import { createSlice } from "@reduxjs/toolkit";
import { TTS_STATUS } from "../constants/TTSStatus";
import { v4 as uuidv4 } from "uuid";
import roundUpToDecimal from "../../../utils/roundUpToDecimal";
import { ElevenLabsVoiceProvider } from "../constants/VoiceProviders";
import updateTTSTimelineMeta from "../helperFunctions/updateTTSTimelineMeta";
import { PROFANITY_STATUS } from "../constants/profanityStatus";
import { concat, intersection } from "lodash";

const initialState = {
  selectedVoices: [],
  TTSTimelineVoicesMP3: [],
  // recentlyAddedVoice: [],
  isVoiceListModalOpen: false,
  voiceListModalAction: "ADD_VOICE",
  isDictModalOpen: false,
  isUpdateProjectLengthSameAsTTSVoicesModalOpen: false,
  getUpdatedData: false,
  replaceVoiceMeta: null,
  isTTSProcessing: false,
  isTTSVoicePlaying: false,
  pendingTTSScriptIds: [],
};

const voicesSlice = createSlice({
  name: "VOICES",
  initialState,
  reducers: {
    ADD_VOICE: (state, action) => {
      state.selectedVoices?.splice(
        state.selectedVoices?.length,
        0,
        action.payload
      );
    },
    UPDATE_TTS_STATUS: (state, action) => {
      action.payload.forEach((data) => {
        // console.log("data", data);
        let obj = state.selectedVoices
          ?.flatMap((item) => item?.content)
          ?.find((item) => item?.voiceUUID === data.scriptId);
        // console.log("obj", JSON.stringify(obj, null, 2));
        if (obj) {
          obj.status = data?.ttsStatus;
          if (data?.profanity) {
            obj.profanityStatus = data?.profanityStatus;
          }
        }
      });
      state.getUpdatedData = !state.getUpdatedData;
    },
    UPDATE_TTS_PROFANITY_META: (state, action) => {
      let { response, profanity } = action.payload;
      // let profanity = false;
      // let response = [
      //   {
      //     scriptId: "548ca74c-c24c-450b-a9f9-19d9db021e0c",
      //     status: "completed",
      //     mp3Path:
      //       "https://aivoice.logthis.in/AudioFiles/51/Luca_-_ calm,_soothing,_steady_202409120735461907_76c55aa8-044c-4edb-ad9b-ec0c07c08ccb.mp3",
      //     duration: 1.4889795,
      //     purifyRequestId: null,
      //     expletiveWordCount: null,
      //     ampProfanity: null,
      //     expletiveWords: null,
      //     profanityStatus: "off",
      //   },
      //   {
      //     scriptId: "6610bae4-32b0-4c19-b545-4973de4712cf",
      //     status: "failed",
      //     mp3Path: "",
      //     duration: 0,
      //     purifyRequestId: null,
      //     expletiveWordCount: null,
      //     ampProfanity: null,
      //     expletiveWords: null,
      //     profanityStatus: "off",
      //   },
      // ];
      // console.log("response", response);

      // update tts meta
      let completedTTS =
        response?.filter((data) => data.status === TTS_STATUS.COMPLETED) || [];

      let failedTTS =
        response?.filter((data) =>
          [TTS_STATUS.FAILED, TTS_STATUS.ERROR].includes(data.status)
        ) || [];

      // console.log("completedTTS", completedTTS);
      // console.log("failedTTS", failedTTS);

      let isTTSDataUpdate = false;
      if (completedTTS?.length > 0) {
        completedTTS.forEach((tts) => {
          let obj = state.selectedVoices
            ?.flatMap((data) => data?.content)
            ?.find((data) => data?.voiceUUID === tts.scriptId);

          if (obj && obj?.status !== TTS_STATUS.COMPLETED) {
            // console.log("TTS completedTTS updated...");
            isTTSDataUpdate = true;
            obj.status = TTS_STATUS.COMPLETED;
            obj.mp3 = tts.mp3Path;
            obj.duration = roundUpToDecimal(tts.duration) || 0;
          }
        });
      }

      if (failedTTS?.length > 0) {
        failedTTS.forEach((data) => {
          let obj = state.selectedVoices
            ?.flatMap((item) => item?.content)
            ?.find((item) => item?.voiceUUID === data?.scriptId);

          if (
            obj &&
            ![TTS_STATUS.FAILED, TTS_STATUS.ERROR].includes(obj?.status)
          ) {
            // console.log("TTS failedTTS updated...");
            isTTSDataUpdate = true;
            obj.status = TTS_STATUS.NOT_STARTED;
            obj.statusMessage = data?.statusMessage || "";
          }
        });
      }

      let completedOrFailedTTSScriptIds =
        concat(completedTTS, failedTTS)?.map((item) => item.scriptId) || [];

      // console.log(
      //   "completedOrFailedTTSScriptIds",
      //   completedOrFailedTTSScriptIds
      // );
      if (!profanity) {
        // remove completedOrFailedTTSScriptIds from pendingTTSScriptIds
        state.pendingTTSScriptIds = state.pendingTTSScriptIds.filter(
          (item) => !completedOrFailedTTSScriptIds.includes(item)
        );
      }

      // update profanity meta
      let isProfanityDataUpdate = false;
      if (profanity) {
        let completedProfanity =
          response?.filter(
            (data) => data.profanityStatus === PROFANITY_STATUS.COMPLETED
          ) || [];

        let failedProfanity =
          response?.filter((data) =>
            [PROFANITY_STATUS.FAILED, PROFANITY_STATUS.ERROR].includes(
              data.profanityStatus
            )
          ) || [];

        // console.log("completedProfanity", completedProfanity);
        // console.log("failedProfanity", failedProfanity);

        if (completedProfanity?.length > 0) {
          completedProfanity.forEach((profanity) => {
            let obj = state.selectedVoices
              ?.flatMap((data) => data?.content)
              ?.find((data) => data?.voiceUUID === profanity.scriptId);

            if (obj && obj?.profanityStatus !== PROFANITY_STATUS.COMPLETED) {
              // console.log("Profanity completedProfanity updated...");
              isProfanityDataUpdate = true;
              obj.profanityStatus = PROFANITY_STATUS.COMPLETED;
              obj.expletiveWordCount = profanity.expletiveWordCount;
              obj.expletiveWords = profanity.expletiveWords;
              obj.ampProfanity = profanity.ampProfanity;
            }
          });
        }

        if (failedProfanity?.length > 0) {
          failedProfanity.forEach((data) => {
            let obj = state.selectedVoices
              ?.flatMap((item) => item?.content)
              ?.find((item) => item?.voiceUUID === data?.scriptId);

            if (
              obj &&
              ![PROFANITY_STATUS.FAILED, PROFANITY_STATUS.ERROR].includes(
                obj?.status
              )
            ) {
              // console.log("Profanity failedProfanity updated...");
              isProfanityDataUpdate = true;
              obj.profanityStatus = PROFANITY_STATUS.FAILED;
            }
          });
        }

        let completedOrFailedProfanityScriptIds =
          concat(completedProfanity, failedProfanity)?.map(
            (item) => item.scriptId
          ) || [];

        // console.log(
        //   "completedOrFailedProfanityScriptIds",
        //   completedOrFailedProfanityScriptIds
        // );
        let proccessedTTSAndProfanityScriptIds = intersection(
          completedOrFailedTTSScriptIds,
          completedOrFailedProfanityScriptIds
        );
        // console.log(
        //   "proccessedTTSAndProfanityScriptIds",
        //   proccessedTTSAndProfanityScriptIds
        // );
        // remove proccessedTTSAndProfanityScriptIds from pendingTTSScriptIds
        state.pendingTTSScriptIds = state.pendingTTSScriptIds.filter(
          (item) => !proccessedTTSAndProfanityScriptIds.includes(item)
        );
      }

      // console.log("isTTSDataUpdate", isTTSDataUpdate);
      // console.log("isProfanityDataUpdate", isProfanityDataUpdate);

      if (isTTSDataUpdate || isProfanityDataUpdate) {
        // console.log("UPDATE getUpdatedData**");
        //will set voices on timeline
        state.TTSTimelineVoicesMP3 = updateTTSTimelineMeta(
          state.selectedVoices,
          state.TTSTimelineVoicesMP3
        );
        //will rerender voice tab
        state.getUpdatedData = !state.getUpdatedData;
      }
    },
    // UPDATE_COMPLETED_TTS_META: (state, action) => {
    //   let completedTTS = action.payload;
    //   if (!Array.isArray(action.payload) || action.payload?.length === 0)
    //     return;

    //   completedTTS.forEach((tts) => {
    //     let obj = state.selectedVoices
    //       ?.flatMap((data) => data?.content)
    //       ?.find((data) => data?.voiceUUID === tts.scriptId);
    //     if (obj) {
    //       obj.status = TTS_STATUS.COMPLETED;
    //       obj.mp3 = tts.mp3Path;
    //       obj.duration = roundUpToDecimal(tts.duration) || 0;
    //     }
    //   });

    //   let completedTTSScriptIds =
    //     completedTTS?.map((completedTTS) => completedTTS.scriptId) || [];

    //   state.pendingTTSScriptIds = state.pendingTTSScriptIds.filter(
    //     (item) => !completedTTSScriptIds.includes(item)
    //   );

    //   state.TTSTimelineVoicesMP3 = updateTTSTimelineMeta(
    //     state.selectedVoices,
    //     state.TTSTimelineVoicesMP3
    //   );
    //   state.getUpdatedData = !state.getUpdatedData;
    // },
    // UPDATE_COMPLETED_TTS_AND_FAILED_PROFANITY: (state, action) => {
    //   let completedTTS = action.payload;
    //   if (!Array.isArray(action.payload) || action.payload?.length === 0)
    //     return;

    //   completedTTS.forEach((tts) => {
    //     let obj = state.selectedVoices
    //       ?.flatMap((data) => data?.content)
    //       ?.find((data) => data?.voiceUUID === tts.scriptId);
    //     if (obj) {
    //       obj.status = TTS_STATUS.COMPLETED;
    //       obj.mp3 = tts.mp3Path;
    //       obj.duration = roundUpToDecimal(tts.duration) || 0;
    //     }
    //   });

    //   let completedTTSScriptIds =
    //     completedTTS?.map((completedTTS) => completedTTS.scriptId) || [];

    //   state.pendingTTSScriptIds = state.pendingTTSScriptIds.filter(
    //     (item) => !completedTTSScriptIds.includes(item)
    //   );

    //   state.TTSTimelineVoicesMP3 = updateTTSTimelineMeta(
    //     state.selectedVoices,
    //     state.TTSTimelineVoicesMP3
    //   );
    //   state.getUpdatedData = !state.getUpdatedData;
    // },
    // UPDATE_COMPLETED_TTS_PROFANITY_META: (state, action) => {
    //   let { completedTTS, completedProfanity } = action.payload;
    //   console.log("completedTTS", completedTTS);
    //   console.log("completedProfanity", completedProfanity);

    //   let isTTSDataUpdate = false;
    //   if (completedTTS?.length > 0) {
    //     completedTTS.forEach((tts) => {
    //       let obj = state.selectedVoices
    //         ?.flatMap((data) => data?.content)
    //         ?.find((data) => data?.voiceUUID === tts.scriptId);
    //       if (obj && obj?.status !== TTS_STATUS.COMPLETED) {
    //         console.log("TTS updated...");
    //         isTTSDataUpdate = true;
    //         obj.status = TTS_STATUS.COMPLETED;
    //         obj.mp3 = tts.mp3Path;
    //         obj.duration = roundUpToDecimal(tts.duration) || 0;
    //       }
    //     });
    //   }

    //   let isProfanityDataUpdate = false;
    //   if (completedProfanity?.length > 0) {
    //     completedProfanity.forEach((profanity) => {
    //       let obj = state.selectedVoices
    //         ?.flatMap((data) => data?.content)
    //         ?.find((data) => data?.voiceUUID === profanity.scriptId);

    //       if (obj && obj?.profanityStatus !== PROFANITY_STATUS.COMPLETED) {
    //         console.log("Profanity updated...");
    //         isProfanityDataUpdate = true;
    //         obj.profanityStatus = PROFANITY_STATUS.COMPLETED;
    //         obj.expletiveWordCount = profanity.expletiveWordCount;
    //         obj.expletiveWords = profanity.expletiveWords;
    //         obj.ampProfanity = profanity.ampProfanity;
    //       }
    //     });
    //   }

    //   let completedTTSScriptIds =
    //     completedTTS?.map((completedTTSItem) => completedTTSItem.scriptId) ||
    //     [];

    //   let completedProfanityScriptIds =
    //     completedProfanity?.map(
    //       (completedProfanityItem) => completedProfanityItem.scriptId
    //     ) || [];

    //   console.log("completedTTSScriptIds", completedTTSScriptIds);
    //   console.log("completedProfanityScriptIds", completedProfanityScriptIds);

    //   let completedTTSAndProfanityScriptIds = completedTTSScriptIds.filter(
    //     (element) => completedProfanityScriptIds.includes(element)
    //   );

    //   console.log(
    //     "completedTTSAndProfanityScriptIds",
    //     completedTTSAndProfanityScriptIds
    //   );

    //   state.pendingTTSScriptIds = state.pendingTTSScriptIds.filter(
    //     (item) => !completedTTSAndProfanityScriptIds.includes(item)
    //   );

    //   console.log("isTTSDataUpdate", isTTSDataUpdate);
    //   console.log("isProfanityDataUpdate", isProfanityDataUpdate);

    //   if (isTTSDataUpdate || isProfanityDataUpdate) {
    //     console.log("UPDATE getUpdatedData**");
    //     state.TTSTimelineVoicesMP3 = updateTTSTimelineMeta(
    //       state.selectedVoices,
    //       state.TTSTimelineVoicesMP3
    //     );
    //     state.getUpdatedData = !state.getUpdatedData;
    //   }
    // },
    // UPDATE_FAILED_TTS_PROFANITY_AND_REMOVE_FROM_PENDING_SCRIPTIDS: (
    //   state,
    //   action
    // ) => {
    //   action.payload.forEach((data) => {
    //     let obj = state.selectedVoices
    //       ?.flatMap((item) => item?.content)
    //       ?.find((item) => item?.voiceUUID === data?.scriptId);
    //     console.log("obj", JSON.stringify(obj, null, 2));
    //     if (obj) {
    //       let failedScriptId = data?.scriptId;
    //       console.log("Removed failed pendingTTSScriptId :: ", failedScriptId);
    //       state.pendingTTSScriptIds = state.pendingTTSScriptIds?.filter(
    //         (item) => item !== failedScriptId
    //       );
    //       obj.status = TTS_STATUS.NOT_STARTED;
    //       if (data?.profanity) {
    //         obj.profanityStatus = PROFANITY_STATUS.NOT_STARTED;
    //       }
    //     }
    //   });
    //   state.getUpdatedData = !state.getUpdatedData;
    // },
    ADD_NEW_TTS_SCRIPT_IDS: (state, action) => {
      state.pendingTTSScriptIds = [
        ...new Set([...state.pendingTTSScriptIds, ...(action.payload || [])]),
      ];
    },
    REMOVE_VOICE: (state, action) => {
      state.selectedVoices = state.selectedVoices
        ?.filter((voice, i) => i !== action.payload?.voiceIndex)
        ?.map((item, itemIndex) => {
          if (item) {
            return {
              ...item,
              content:
                item?.content?.map((contentItem) => ({
                  ...contentItem,
                  voiceIndex: itemIndex,
                })) || [],
            };
          } else {
            return;
          }
        });

      state.TTSTimelineVoicesMP3 = state.TTSTimelineVoicesMP3?.filter(
        (data) => data.mp3 !== action.payload?.mp3
      );
      state.pendingTTSScriptIds = state.pendingTTSScriptIds?.filter(
        (data) => data !== action.payload?.content?.[0]?.voiceUUID
      );
    },
    // ADD_RECENT_VOICE: (state, action) => {
    //   let newRecentlyAddedVoiceList = getUniqueListBy(
    //     [action.payload, ...state.recentlyAddedVoice],
    //     "voiceId"
    //   );
    //   if (
    //     newRecentlyAddedVoiceList?.length >
    //     window?.globalConfig?.TOP_RECENTLY_ADDED_VOICE_RESULTS
    //   ) {
    //     newRecentlyAddedVoiceList = newRecentlyAddedVoiceList?.slice(
    //       0,
    //       window?.globalConfig?.TOP_RECENTLY_ADDED_VOICE_RESULTS
    //     );
    //   }
    //   state.recentlyAddedVoice = newRecentlyAddedVoiceList;
    // },
    ADD_CONTENT: (state, action) => {
      state.selectedVoices[action.payload.voiceIndex].content =
        action.payload?.inputList;

      const removedTextBoxMp3 = action.payload?.removedTextBoxMp3;
      if (!!removedTextBoxMp3) {
        state.TTSTimelineVoicesMP3 = state.TTSTimelineVoicesMP3?.filter(
          (data) => data?.mp3 !== removedTextBoxMp3
        );
      }
      const removedTextBoxUUID = action.payload?.removedTextBoxUUID;
      if (!!removedTextBoxUUID) {
        state.pendingTTSScriptIds = state.pendingTTSScriptIds?.filter(
          (data) => data !== removedTextBoxUUID
        );
      }
    },
    ADD_SUBTEXT: (state, action) => {
      let { profanity } = action.payload;
      const lastContent =
        state.selectedVoices[state.selectedVoices?.length - 1]?.content;

      state.selectedVoices[state.selectedVoices?.length - 1].content =
        lastContent?.concat({
          content: "",
          voice: lastContent?.[0]?.voice,
          color: lastContent?.[0]?.color,
          gender: lastContent?.[0]?.gender,
          voiceProvider: lastContent?.[0]?.voiceProvider,
          mp3: "",
          ...(profanity && {
            expletiveWordCount: null,
            ampProfanity: null,
            expletiveWords: null,
            profanityStatus: PROFANITY_STATUS.NOT_STARTED,
            langCode: lastContent?.[0]?.langCode,
          }),
          artistName: lastContent?.[0]?.artistName,
          status: TTS_STATUS.NOT_STARTED,
          statusMessage: "",
          voiceUUID: uuidv4(),
          duration: 0,
          ...(lastContent?.[0]?.voiceProvider !== ElevenLabsVoiceProvider
            ? {
                speed: lastContent?.[0]?.speed || "1",
                pitch: lastContent?.[0]?.pitch || "1",
              }
            : {
                stability: lastContent?.[0]?.stability || "0.5",
                similarityBoost: lastContent?.[0]?.similarityBoost || "0.7",
                style: lastContent?.[0]?.style || "0",
                useSpeakerBoost: lastContent?.[0]?.useSpeakerBoost || true,
              }),
          speakingStyle: lastContent?.[0]?.speakingStyle || "general",
          index: lastContent?.length,
          voiceIndex: lastContent?.[0]?.voiceIndex,
        });
      state.getUpdatedData = !state.getUpdatedData;
    },
    UPDATE_TTS_TIMELINE_SLIDER_VALUES: (state, action) => {
      state.TTSTimelineVoicesMP3.forEach((element, index) => {
        if (action.payload?.[index] >= 0) {
          element.startPoint = roundUpToDecimal(action.payload?.[index]);
        }
      });
    },
    SET_VOICE_META: (state, action) => {
      let voiceObj = action.payload;
      for (const key in voiceObj) {
        if (Object.hasOwnProperty.call(voiceObj, key)) {
          const element = voiceObj[key];
          state[key] = element;
        }
      }
    },
    RESET_VOICE_META: () => initialState,
  },
});

// export const updateCompletedTTSMeta = (payload) => {
//   return (dispatch, getState) => {
//     dispatch(UPDATE_COMPLETED_TTS_META(payload));
//   };
// };

export default voicesSlice.reducer;
export const {
  ADD_VOICE,
  ADD_SUBTEXT,
  REMOVE_VOICE,
  UPDATE_TTS_STATUS,
  UPDATE_TTS_PROFANITY_META,
  // UPDATE_FAILED_TTS_PROFANITY_AND_REMOVE_FROM_PENDING_SCRIPTIDS,
  // UPDATE_COMPLETED_TTS_META,
  // UPDATE_COMPLETED_TTS_PROFANITY_META,
  ADD_NEW_TTS_SCRIPT_IDS,
  GET_PENDING_TTS,
  // ADD_RECENT_VOICE,
  ADD_CONTENT,
  SET_SELECTED_VOICES,
  UPDATE_TTS_TIMELINE_SLIDER_VALUES,
  SET_VOICE_META,
  RESET_VOICE_META,
} = voicesSlice.actions;
