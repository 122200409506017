import React, { Suspense } from "react";
import { brandConstants } from "../../utils/brandConstants";
import getBrandName from "../../utils/getBrandName";

const WPPModal = React.lazy(() => import("../wpp/components/Modal/WPPModal"));
const SonicModal = React.lazy(() =>
  import("../sonicspace/components/Modal/SonicModal")
);

const brandName = getBrandName();

const RenderModal = (props) => {
  switch (brandName) {
    case brandConstants.WPP:
      return <WPPModal {...props} />;
    default:
      return <SonicModal {...props} />;
  }
};

const ModalWrapper = (props) => {
  return (
    <Suspense fallback={<></>}>
      <RenderModal {...props} />
    </Suspense>
  );
};

export default ModalWrapper;
